import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79a76cb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chip-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: "chip",
    style: _normalizeStyle(`color: ${_ctx.color}; background-color: ${_ctx.backgroundColor ?? 'black'}`)
  }, [
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 4))
}