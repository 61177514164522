
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import MapView from "@/views/MapView.vue";
import Chip from "@/components/Chip.vue";
import { Getter, Mutation, Action } from "vuex-class";
import IMapData from "@/data/interfaces/IMapData";
import { Container, Row, Col } from "iron-grid-system";
import ICallout from "@/data/interfaces/ICallout";
import AuthHandler from "@/util/AuthHandler";
import { ElUpload } from "element-plus";
import axios, { AxiosRequestConfig } from "axios";
import CalloutUtil, { TypeValue } from "@/data/classes/CalloutUtil";
import {
  communityColor,
  officialColor,
  minorColor,
} from "@/data/classes/CalloutUtil";

interface NewMapFormData {
  name: string;
  minimapsList: any[];
  minimap?: Blob;
  loadingScreenList: any[];
  loadingScreen?: Blob;
}

@Options({
  components: {
    MapView,
    Container,
    Row,
    Col,
    Chip,
  },
})
export default class Home extends Vue {
  editMode = false;
  learnMode = true;
  addMapDialogVisible = false;
  addMapFormData: NewMapFormData = {
    name: "",
    minimapsList: [],
    minimap: undefined,
    loadingScreenList: [],
    loadingScreen: undefined,
  };
  tagFilter = "";

  minorColor = minorColor;
  officialColor = officialColor;
  communityColor = communityColor;

  showOfficial = true;
  showCommunity = true;
  showMinor = true;

  $refs!: {
    loadingScreenUl: HTMLInputElement;
    minimapUl: typeof ElUpload;
    addMapForm: HTMLFormElement;
  };

  @Getter("getActiveMap")
  getActiveMap!: number;
  @Getter("getMaps")
  maps!: IMapData[];
  @Getter("isLoggedIn")
  isLoggedIn!: boolean;
  @Getter("canEdit")
  canEdit!: boolean;

  @Mutation("setActiveMap")
  setActiveMap!: (value: number) => void;

  @Action("login")
  login!: () => void;
  @Action("logout")
  logout!: () => void;
  @Action("fetchMaps")
  fetchMaps!: () => void;

  mounted() {
    this.fetchMaps();
  }

  get map() {
    return this.maps?.[this.getActiveMap];
  }

  get tabMap(): number {
    return this.getActiveMap;
  }

  set tabMap(value: number) {
    this.setActiveMap(value);
  }

  get shownCallouts(): ICallout[] {
    const result: ICallout[] =
      this.map?.callouts.filter(
        (c) =>
          (c.type === 0 && this.showCommunity) ||
          (c.type === 1 && this.showOfficial) ||
          (c.type === 2 && this.showMinor)
      ) ?? [];
    return result;
  }

  get filteredCallouts(): ICallout[] {
    let result: ICallout[];
    if (!this.tagFilter || this.tagFilter.length < 3)
      result = this.shownCallouts;
    else {
      result = this.shownCallouts.filter((c) =>
        c.name.toLowerCase().includes(this.tagFilter.toLowerCase())
      );
    }
    result = result
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => a.type - b.type);
    return result;
  }

  calloutTypeValues(callout: ICallout): TypeValue {
    return CalloutUtil.typeData(callout);
  }

  saveCallouts() {
    if (!this.map) {
      return;
    }
    axios.put(`/api/callouts/${this.map?.id}`, {
      callouts: this.map?.callouts,
    });
  }

  dragstart(e: DragEvent, o: ICallout) {
    console.log("Data:", e.dataTransfer);
    e.dataTransfer?.setData("name", o.name);
    console.log("Data:", e.dataTransfer?.getData("name"));
  }

  handleLoadingScreenUlChange(file: any, targetArray: any[]) {
    this.addMapFormData.loadingScreen = file.raw;
    this.handleUlChange(file, targetArray);
  }

  handleMinimapUlChange(file: any, targetArray: any[]) {
    this.addMapFormData.minimap = file.raw;
    this.handleUlChange(file, targetArray);
  }

  handleUlChange(file: any, targetArray: any[]): void {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file.raw);
    reader.onload = (e) => {
      const i = targetArray.findIndex((x) => x.raw.uid === file.raw.uid);
      targetArray.splice(i, 1);
      targetArray.push({
        name: file.raw.name,
        url: e?.target?.result,
      });
    };
  }

  handleUlRemove(file: File, target: File[]) {
    const i = target.findIndex((x) => x.name === file.name);
    target.splice(i, 1);
  }

  submitNewMap(event: Event) {
    const formData = new FormData();

    if (
      !this.addMapFormData.name ||
      !this.addMapFormData.minimap ||
      !this.addMapFormData.loadingScreen
    )
      return;

    formData.append("minimap", this.addMapFormData.minimap);
    formData.append("loadingScreen", this.addMapFormData.loadingScreen);
    formData.append("name", this.addMapFormData.name);

    const config: AxiosRequestConfig<FormData> = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios.post("/api/maps/create", formData, config);
  }
}
