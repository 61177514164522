
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import Callout from "@/components/Callout.vue";
import { Container } from "iron-grid-system";
import { Action, Getter, Mutation } from "vuex-class";
import ICallout from "@/data/interfaces/ICallout";
import IMapData from "@/data/interfaces/IMapData";
import { ElUpload } from "element-plus";
import { Prop, Watch } from "vue-property-decorator";
import {
  communityColor,
  officialColor,
  minorColor,
} from "@/data/classes/CalloutUtil";
import { Refresh, RefreshLeft } from "@element-plus/icons";
import panzoom, { PanZoom } from "panzoom";
import ResizeObserver from "resize-observer-polyfill";

@Options({
  name: "MapView",
  components: {
    Callout,
    Container,
    Refresh,
    RefreshLeft,
  },
  methods: {
    modalOk() {
      this.map.callouts.push({
        name: this.debugName,
        x: this.debugX,
        y: this.debugY,
      });
      this.debugName = "";
      this.debugModal = false;

      console.log(this.map);
    },
  },
  computed: {
    loadingScreenImage() {
      return require(`@/assets/maps/loading_screens/${this.map.loadingScreen}`);
    },
  },
  // props: {
  //   learnMode: { type: Boolean, default: true },
  // },
})
export default class MapView extends Vue {
  $refs!: {
    loadingScreenUl: HTMLInputElement;
    minimap: HTMLElement & { $el: HTMLElement };
    addMapForm: HTMLFormElement;
    wrapper: HTMLElement;
  };
  debugModal = false;
  debug = false;
  debugX = 0;
  debugY = 0;
  debugName = "";
  mouseFlag: 0 | 1 = 0;
  panzoomInstance: PanZoom | null = null;

  communityColor = communityColor;
  minorColor = minorColor;
  officialColor = officialColor;

  showCallouts = true;

  showOfficial = true;
  showCommunity = true;
  showMinor = true;

  resizeObserver?: ResizeObserver | null = null;
  size?: number | null = null;

  @Watch("editMode")
  onEditModeChange(val: boolean) {
    if (val) {
      this.panzoomInstance?.moveTo(0, 0); // to be sure to go back to (0,0)
      this.panzoomInstance?.zoomAbs(0, 0, 1); // to be sure to go back to scale 1

      this.panzoomInstance?.pause();
    } else {
      this.panzoomInstance?.resume();
    }
  }

  @Prop({ default: true }) learnMode!: boolean;
  @Prop({ required: true }) callouts!: ICallout[];

  @Getter("getActiveMap")
  activeMapIndex!: number;
  @Getter("getMaps")
  maps!: IMapData[];
  @Mutation("setCallout")
  setCallout!: (data: {
    mapIndex: number;
    calloutIndex: number;
    callout: ICallout;
  }) => void;
  @Mutation("deleteCallout")
  deleteCallout!: (data: { mapIndex: number; calloutIndex: number }) => void;

  @Action("fetchMaps")
  fetchMaps!: () => void;

  get map() {
    return this.maps[this.mapId];
  }

  get filteredCallouts() {
    const result = this.map?.callouts.filter(
      (c) =>
        (c.type === 0 && this.showCommunity) ||
        (c.type === 1 && this.showOfficial) ||
        (c.type === 2 && this.showMinor)
    );
    return result;
  }

  @Prop({ required: true, default: true }) readonly editMode!: boolean;
  @Prop({ required: true }) readonly mapId!: number;

  mounted() {
    console.log("mounted");
    this.setupPanzoom();
    this.size = this.$refs.wrapper.getBoundingClientRect().width;
    this.resizeObserver = new ResizeObserver(this.onMapResize);
    this.resizeObserver.observe(
      this.$refs.wrapper);
  }

  unmounted() {
    this.resizeObserver?.disconnect()
  }

  wrapperMouseDown() {
    this.mouseFlag = 0;
  }
  wrapperMouseMove() {
    this.mouseFlag = 1;
  }
  wrapperMouseUp(event: DragEvent) {
    if (!this.editMode) return;
    console.log("test")
    if (this.mouseFlag === 0) {
      const x = (event.offsetX / this.$refs.minimap.clientWidth) * 100;
      const y = (event.offsetY / this.$refs.minimap.clientHeight) * 100;
      this.setCallout({
        mapIndex: this.activeMapIndex,
        calloutIndex: this.map?.callouts.length,
        callout: { name: "new callout", x, y, type: 0 },
      });
      this.debugModal = true;
    }
  }

  onMapResize(event: any) {
    console.log(event[0].contentRect.width);
    this.size = event[0].contentRect.width
  }

  reset() {
    this.showCallouts = false;
    this.$nextTick(() => {
      this.showCallouts = true;
      this.setupPanzoom();
    });
  }

  setupPanzoom() {
    const wrapper = this.$refs.wrapper;
    this.panzoomInstance = panzoom(wrapper, {
      bounds: true,
      boundsPadding: 0.05,
    });
    if (this.editMode) {
      this.panzoomInstance?.dispose();
    }
  }

  calloutEdited(event: ICallout) {
    const index = this.map.callouts.indexOf(event)
    this.setCallout({
      mapIndex: this.activeMapIndex,
      calloutIndex: index,
      callout: event,
    });
  }

  calloutDeleted(callout: ICallout) {
    console.log("deleteing callout", callout.name);
    console.log("find index", this.map.callouts.indexOf(callout))
    const index = this.map.callouts.indexOf(callout)
    this.deleteCallout({
      mapIndex: this.activeMapIndex,
      calloutIndex: index,
    });
  }
}
