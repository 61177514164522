
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Options({
  name: "Chip",
})
export default class Chip extends Vue {
  @Prop({ required: true }) color!: string;
  @Prop({}) backgroundColor!: string;
}
