import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81d115cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close = _resolveComponent("close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_edit = _resolveComponent("edit")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.editMode ? 'draggable' : 'div'), {
    style: _normalizeStyle(`top: ${_ctx.top}%; left: ${_ctx.left}%; color: ${_ctx.typeValues.color}; font-size: ${_ctx.typeValues.fontSize};`),
    class: "callout",
    onMousedown: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"])),
    onDragstart: _withModifiers(_ctx.dragstart, ["prevent"]),
    onDrag: _withModifiers(_ctx.dragmove, ["prevent"]),
    onDragend: _withModifiers(_ctx.dragend, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_popover, {
        disabled: !_ctx.editMode,
        visible: _ctx.popoverVisible,
        "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.popoverVisible) = $event)),
        trigger: "manual",
        width: "13rem"
      }, {
        default: _withCtx(() => [
          (_ctx.editMode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_el_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.popoverVisible = false)),
                  class: "float-right mb-2",
                  type: "text"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_close)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_input, {
                  class: "mb-1",
                  modelValue: _ctx.editableName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editableName) = $event))
                }, {
                  append: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_edit)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_el_select, {
                  class: "mb-1",
                  modelValue: _ctx.editableType,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editableType) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "Community",
                      value: 0
                    }),
                    _createVNode(_component_el_option, {
                      label: "Official",
                      value: 1
                    }),
                    _createVNode(_component_el_option, {
                      label: "Minor",
                      value: 2
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_el_button, {
                  class: "mb-1",
                  type: "danger",
                  onClick: _ctx.del,
                  circle: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_delete)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]),
        reference: _withCtx(() => [
          _createElementVNode("div", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.popoverVisible = !_ctx.popoverVisible))
          }, [
            (_ctx.showName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.callout.name), 1))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  style: _normalizeStyle(`background-color: ${_ctx.typeValues.color};`),
                  class: "callout-placeholder",
                  onDragover: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
                  onDrop: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.drop && _ctx.drop(...args)))
                }, null, 36))
          ])
        ]),
        _: 1
      }, 8, ["disabled", "visible"])
    ]),
    _: 1
  }, 8, ["style", "onDragstart", "onDrag", "onDragend"]))
}